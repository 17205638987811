var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[(!!_vm.componentType)?_c(_vm.componentType,{tag:"component",attrs:{"title":_vm.formTitle,"paymentMethod":_vm.paymentMethod},on:{"dialog:close":function($event){_vm.componentType = null},"form:success":function($event){return _vm.onFormSuccess($event)},"form:error":function($event){return _vm.showErrorAlert($event)}},model:{value:(_vm.componentType),callback:function ($$v) {_vm.componentType=$$v},expression:"componentType"}}):_vm._e(),_c('tr',{staticClass:"grey lighten-4"},[_c('th',{staticClass:"text-left"},[_c('base-text',{attrs:{"bold":"","body3":"","text":"PAYMENT METHOD"}})],1),_c('th',{staticClass:"text-center",attrs:{"width":"100"}},[_c('base-text',{attrs:{"bold":"","body3":"","text":"STATUS"}})],1)])],1),_c('tbody',_vm._l((_vm.paymentOrderByType),function(method){return _c('tr',{key:method.uuid},[_c('td',[_c('base-text',{attrs:{"text":method.title}})],1),_c('td',{staticClass:"text-right"},[(!!method.appPaymentOption)?_c('v-menu',{attrs:{"value":_vm.methodUuid === method.uuid,"close-on-content-click":false,"nudge-width":150,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":!!method.appPaymentOption && method.appPaymentOption.active
                    ? 'orange darken-5'
                    : 'grey',"pill":"","dark":""},on:{"click:close":function($event){_vm.methodUuid = method.uuid}}},'v-chip',attrs,false),on),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(_vm._s(!!method.appPaymentOption && method.appPaymentOption.active ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline"))])],1),_c('v-row',{staticClass:"text-center payment-status",attrs:{"justify":"center"}},[_c('base-text',{attrs:{"text":!!method.appPaymentOption &&
                      method.appPaymentOption.active
                        ? 'Active'
                        : 'Inactive'}})],1)],1)]}}],null,true)},[_c('v-card',{staticClass:"pt-6"},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_vm._v(" Inactive "),_c('v-switch',{staticClass:"px-2",attrs:{"color":"orange","inset":"","disabled":!method.appPaymentOption,"value":"","input-value":!!method.appPaymentOption &&
                      method.appPaymentOption.active},on:{"change":function($event){return _vm.$emit('payment:toggle', {
                        published: $event,
                        uuid: method.appPaymentOption.uuid,
                      })}}}),_vm._v(" Active ")],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('base-button',{attrs:{"edit":"","small":""},nativeOn:{"click":function($event){return _vm.openFormByType(method)}}},[_c('base-text',{attrs:{"text":"Update Details"}})],1)],1)],1)],1):_c('v-chip',{attrs:{"color":!!method.appPaymentProvider ? 'teal lighten-2' : 'grey darken-1',"dark":""},on:{"click":function($event){return _vm.openFormByType(method)},"click:close":function($event){return _vm.openFormByType(method)}}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(_vm._s(!!method.appPaymentProvider ? "mdi-circle-edit-outline" : "mdi-plus-circle"))])],1),_c('v-row',{staticClass:"text-center payment-status",attrs:{"justify":"center"}},[_c('base-text',{attrs:{"text":!!method.appPaymentProvider ? 'Update' : 'Initialize'}})],1)],1)],1)])}),0)]},proxy:true}],null,false,205180264)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }